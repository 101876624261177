<template>
    <vue-tel-input
        ref="input"
        class="p-0 border-2"
        v-model="phone"
        @on-input="setPhone"
        :inputOptions="inputOptions"
        :onlyCountries="onlyCountries"
        :dropdownOptions="dropdownOptions"
        :autoDefaultCountry="false"
        defaultCountry="ua"
        :validCharactersOnly="true"
        mode="national"
    >
        <template v-slot:arrow-icon>
            <span></span>
        </template>
    </vue-tel-input>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

export default {
    props: {
        modelValue: {
            required: true
        }
    },

    components: {
        VueTelInput,
    },

    data () {
        return {
            phone: '',
            inputOptions: {
                name: 'phone',
                styleClasses: 'px-6 py-3 border-black h-11 text-sm font-bold placeholder-gray-400',
                // styleClasses: 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 rounded-md shadow-sm',
                maxlength: 12,
                placeholder: 'Введи номер телефону',
                type: 'tel',
                autocomplete: `on ${this.$attrs.autocomplete}`,
                tabindex: 0,
                autofocus: true,
                'aria-describedby': true
            },
            dropdownOptions: {
                disabled: true,
                showFlags: true,
                showDialCodeInSelection: true
            },
            onlyCountries: ['ua'],
        }
    },

    methods: {
        setPhone (value, object) {
            this.$emit('update:modelValue', object.number)
        }
    },

    mounted () {
        this.phone = this.modelValue
    }
};
</script>

<style>
.vue-tel-input {
    border-radius: 0;
    border-width: 2px;
    --tw-border-opacity: 1;
    border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.vue-tel-input:focus-within {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity));
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    border-color: #2563eb;
}

.vti__input {
    border-radius: 0;
    --tw-border-opacity: 1;
    border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.vti__dropdown {
    border-radius: 0;
}

.vti__dropdown.disabled {
    cursor: default;
}
</style>
